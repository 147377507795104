import axios from 'axios';
import retry from 'axios-retry';
import variables from '../configs/variables';
import { getHashRate } from '../helpers';

const SEARCH_URL = '/transactions/search';

class _ApiClient {
  constructor() {
    this.server = axios.create({
      baseURL: variables.apiBaseUrl,
      headers: {
        'Access-Control-Allow-Origin': '*',
      },
    });

    retry(this.server, {
      retries: 0,
      retryDelay: (count) => count * 1000,
      retryCondition: (error) => {
        return error.response.status === 404 || error.config.url === SEARCH_URL;
      },
      validateResponse: (response) => {
        const isTxsSearch = response.config.url === SEARCH_URL;
        const isTxsToSearch =
          isTxsSearch &&
          !!JSON.parse(response.config.data).transactionIds.length;
        const isTxsSearchFailed = isTxsToSearch && !response.data.length;
        const isEntityNotFound = response.status === 404;

        return !(isTxsSearchFailed || isEntityNotFound);
      },
    });
  }

  getPrice = async () => {
    const response = await this.server('/info/market-data');
    const result = response.data;

    return {
      price: result.current_price.usd,
      hourChange: result.price_change_percentage_1h_in_currency.usd,
      dayChange: result.price_change_percentage_24h,
      weekChange: result.price_change_percentage_7d,
      volume: result.total_volume.usd,
      rank: result.market_cap_rank,
    };
  };

  getCoinSupply = async () => {
    const response = await this.server.get('/info/coinsupply');
    const result = response.data;

    return {
      totalSupply: result.circulatingSupply,
      maxSupply: result.maxSupply,
    };
  };

  getBlockReward = async () => {
    const response = await this.server.get('/info/blockreward');
    const result = response.data;

    return { blockReward: result.blockreward };
  };

  getHalving = async () => {
    const response = await this.server.get('/info/halving');
    const result = response.data;

    return {
      halvingTimestamp: result.nextHalvingTimestamp,
      halvingAmount: result.nextHalvingAmount,
    };
  };

  getSplit = async () => {
    const response = await this.server.get('/info/split');
    const result = response.data;

    return {
      split: result.split,
      splitTimestamp: result.nextSplitTimestamp,
      splitAmount: result.nextSplitAmount,
    };
  };

  getBlockdagInfo = async () => {
    const response = await this.server.get('/info/blockdag');
    const result = response.data;

    return {
      networkName: result.networkName,
      blockCount: result.blockCount,
      headerCount: result.headerCount,
      virtualDaaScore: result.virtualDaaScore,
      hashrate: getHashRate(result.difficulty),
    };
  };

  getServerInfo = async () => {
    const response = await this.server.get('/info/kaspad');
    const result = response.data;

    return {
      mempoolSize: result.mempoolSize,
      serverVersion: result.serverVersion,
    };
  };

  getBlockDetails = async (hash) => {
    const response = await this.server.get(`/blocks/${hash}`);
    return response.data;
  };

  getTransactionList = async (ids) => {
    const response = await this.server.post(
      '/transactions/search',
      {
        transactionIds: ids,
      },
      {
        headers: { 'Content-Type': 'application/json' },
        'axios-retry': {
          retries: 10,
        },
      },
    );

    return response.data;
  };

  getTransactionDetails = async (id) => {
    const response = await this.server.get(`/transactions/${id}`, {
      'axios-retry': {
        retries: 10,
      },
    });
    return response.data;
  };

  getAddressBalance = async (publicKey) => {
    const response = await this.server.get(`/addresses/${publicKey}/balance`);
    return response.data.balance;
  };

  getAddressUtxos = async (publicKey) => {
    const response = await this.server.get(`/addresses/${publicKey}/utxos`);
    return response.data;
  };

  getAddressTxCount = async (publicKey) => {
    const response = await this.server.get(
      `/addresses/${publicKey}/transactions-count`,
    );
    return response.data.total;
  };

  getAddressTransactions = async (publicKey, offset, limit) => {
    const response = await this.server.get(
      `/addresses/${publicKey}/full-transactions`,
      {
        params: {
          offset,
          limit,
        },
      },
    );

    return response.data;
  };
}

const ApiClient = new _ApiClient();

export default ApiClient;
