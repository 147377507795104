import React, { createContext, useContext, useEffect, useState } from 'react';
import ApiClient from '../services/api-client';
import variables from '../configs/variables';

const defaultState = {
  totalSupply: 0,
  maxSupply: 0,
  blockReward: 0,
  halvingTimestamp: 0,
  halvingAmount: 0,
  split: 0,
  splitTimestamp: 0,
  splitAmount: 0,
  networkName: 'Network',
  blockCount: 0,
  headerCount: 0,
  virtualDaaScore: 0,
  hashrate: 0,
  mempoolSize: 0,
  serverVersion: 0,
};

const StatsContext = createContext(defaultState);

const StatsProvider = ({ children }) => {
  const [stats, setStats] = useState(defaultState);

  const processStats = async () => {
    const promises = [
      ApiClient.getCoinSupply(),
      ApiClient.getBlockReward(),
      ApiClient.getHalving(),
      ApiClient.getSplit(),
      ApiClient.getBlockdagInfo(),
      ApiClient.getServerInfo(),
    ];

    const resultsArray = await Promise.all(promises);
    const results = resultsArray.reduce(
      (acc, current) => ({ ...acc, ...current }),
      {},
    );

    setStats((prev) => ({
      ...prev,
      totalSupply: Math.round(results.totalSupply / variables.coinParts),
      maxSupply: Math.round(results.maxSupply / variables.coinParts),
      blockReward: results.blockReward,
      halvingTimestamp: results.halvingTimestamp,
      halvingAmount: results.halvingAmount,
      split: results.split,
      splitTimestamp: results.splitTimestamp,
      splitAmount: results.splitAmount,
      networkName: results.networkName,
      blockCount: results.blockCount,
      headerCount: results.headerCount,
      virtualDaaScore: results.virtualDaaScore,
      hashrate: results.hashrate,
      mempoolSize: results.mempoolSize,
      serverVersion: results.serverVersion,
    }));
  };

  useEffect(() => {
    processStats();
    const interval = setInterval(
      processStats,
      variables.statsCheckInterval * 1000,
    );

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <StatsContext.Provider value={stats}>{children}</StatsContext.Provider>
  );
};

const useStats = () => {
  return useContext(StatsContext);
};

export { StatsProvider };
export default useStats;
